/* global localStorage */
/* global handleModal */
/* global i18next */
/* global nJs */

nJs.ready(function () {
  // Handler Sign Out button
  var btn_logout_el = document.querySelector("[data-as='btn-logout']");
  if(btn_logout_el !== null) {
    btn_logout_el.addEventListener("click", function (event) {
      event.preventDefault();
      handleModal.confirm_modal({ text: i18next.t("layouts.pull_down_menu.confirm_sign_out") }, function (r) {
        if (r) {
          // follow standard logout link, just trigger click on it.
          document.querySelector("#hidden-btn-logout").click(); // Reference: https://stackoverflow.com/a/34174253 (kevin - 01/21/2020)
        }
      });
    }, false);
  }
});

window.onload = function(){
  if (document.querySelector("body").getAttribute("data-auto-logout") === "true") {
    localStorage.setItem('isUserLogged', 1);
    window.addEventListener('storage', function(event){
      if (event.key === 'isUserLogged' && !localStorage.getItem('isUserLogged')) {
        window.location.reload();
      }
    }, false);
  } else {
    localStorage.removeItem("isUserLogged");
  }
};
