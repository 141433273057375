/* global Hammer */
delete Hammer.defaults.cssProps.userSelect;

var nJs = {
  displays: function(selector) {
    var elem = document.querySelector(selector);
     if(typeof(elem) !== 'undefined' && elem !== null) {
        elem.style.display = '';
     }
  },
  conceal: function(selector) {
    document.querySelector(selector).style.display = 'none';
  },
  conceal_siblings: function (elem, selector) {
    try {
    	var sibling = elem.parentNode.firstChild;
    	for (; sibling; sibling = sibling.nextSibling) {
    		if (sibling.nodeType !== 1 || sibling === elem) continue;
    		if (sibling.tagName.toLowerCase() === selector) {
    		  sibling.style.display = 'none';
    		}
      }
    }catch(e){
      console.log(e.message);
    }
  },
  add_class: function(elem, selector_class) {
    try{
      if(typeof(elem) !== 'undefined' && elem !== null) {
        elem.classList.add(selector_class);
      }
    }catch(e){
      console.log(e.message);
    }
  },
  remove_class: function(elem, selector_class) {
    try {
      elem.classList.remove(selector_class);
    }catch(e){
      console.log(e.message);
    }
  },
  toggle_class: function(el, selector_class) {
    if(typeof(el) !== 'undefined' && el !== null) {
      el.classList.toggle(selector_class);
    }
  },
  has_class: function(el, class_name) {
    if (el.className.indexOf(class_name) !== -1) {
      return true;
    } else {
      return false;
    }
  },
  ready: function(callback) {
    if (document.readyState !== "loading") callback();
    else document.addEventListener("DOMContentLoaded", callback);
  },
  siblings: function(el){
    return Array.from(el.parentNode.children).filter((child) => child !== el);
  },
  getOffset: function (el) {
    const box = el.getBoundingClientRect();
    return {
      top: box.top + window.pageYOffset - document.documentElement.clientTop,
      left: box.left + window.pageXOffset - document.documentElement.clientLeft
    };
  },
  fadeOut: function(el, ms){
    if (ms) {
      el.style.transition = `opacity ${ms} ms`;
      el.addEventListener(
        'transitionend',
        function(event) {
          el.style.display = 'none';
        },
        false
      );
    }
    el.style.opacity = '0';
  },
  run_animate: function(el, ms, transform) {
    if(typeof(el) !== 'undefined' && el !== null) {
      el.animate([ 
        { transform: transform }
      ], {
        duration: ms
      });
    }
  },
  remove: function(elm){
    if(elm){
      elm.parentNode.removeChild(elm);
    }
  },
  stringToHTML: function (str) {
  	var dom = document.createElement('div');
  	dom.setAttribute('class','row no-gutters');
  	dom.innerHTML = str;
  	return dom;
  },
  
  parentsUntil: function(el, selector, filter) {
    const result = [];
    const matchesSelector = el.matches || el.webkitMatchesSelector || el.mozMatchesSelector || el.msMatchesSelector;
  
    // match start from parent
    el = el.parentElement;
    while (el && !matchesSelector.call(el, selector)) {
      if (!filter) {
        result.push(el);
      } else {
        if (matchesSelector.call(el, filter)) {
          result.push(el);
        }
      }
      el = el.parentElement;
    }
    return result;
  },
  
  arrayLikeChildren: function(arrayLike, selector){
    var hits_child = [];
    Array.from(arrayLike).filter(function(elm){
        Array.from(elm.children).filter(function(elm2){
          if(typeof selector === 'undefined' || selector==="" || selector===null || selector.length===0){
            hits_child.push(elm2);
          }else{
            if(elm2.matches(selector)){
                hits_child.push(elm2);
            }
          }
        });
    });
    return hits_child;
  },
  
  children: function(elm, selector){
    var hits_child = [];
    Array.from(elm.children).filter(function(elm2){
      if(typeof selector === 'undefined' || selector==="" || selector===null || selector.length===0){
        hits_child.push(elm2);
      }else{
        if(elm2.matches(selector)){
            hits_child.push(elm2);
        }
      }
    });

    return hits_child;
  },
  
  arrayLikeFind: function(arrayLike, selector){
    var array_find = [];
    Array.from(arrayLike).filter(function(elm){
        array_find.push.apply(array_find, Array.from(elm.querySelectorAll(selector)));
    });
    return array_find;
  },
  
  removeElementsByClass: function(className){
    var elements = document.getElementsByClassName(className);
    while(elements.length > 0){
      elements[0].parentNode.removeChild(elements[0]);
    }
  },
  
  insertAfter: function(newNode, referenceNode) {
    referenceNode.parentNode.insertBefore(newNode, referenceNode.nextSibling);
  },
  
  /*: function(input) {
    var doc = new DOMParser().parseFromString(input, "text/html");
    return doc.documentElement.textContent;
  }*/
  
  width: function(node) {
    node.innerWidth||node.clientWidth;
  },
  
  height: function(node) {
    node.innerHeight||node.clientHeight;
  },
  
  fadeOutEffect: function(element, time) {
    var fadeTarget = element;
    var fadeEffect = setInterval(function () {
        if (!fadeTarget.style.opacity) {
            fadeTarget.style.opacity = 1;
        }
        if (fadeTarget.style.opacity > 0) {
            fadeTarget.style.opacity -= 0.1;
        } else {
            clearInterval(fadeEffect);
        }
    }, time);
  },
  
  confirmModalCancel: function() {
    var cancel_btn_el = document.getElementById('confirm-modal-cancel-btn-id');
    if(cancel_btn_el!==null) {
      cancel_btn_el.click(); 
    }
  }


};
export default nJs;
window.nJs = nJs;