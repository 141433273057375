/* global i18next */
if('serviceWorker' in navigator) {
  window.addEventListener('load', () => {
    navigator.serviceWorker.register('/service-worker.js', { scope: "/"  })
      .then(function(registration){
        console.log("registration sucessful: ", registration.scope);
      }, function(err){
        console.log("registration failed: ", err)
      })
  })
  if (navigator.onLine === false) {
    console.log('offline here..');
    var notification_txt = i18next.t("layouts.offline.notification");
    console.log (notification_txt);
    let div = document.createElement("div");
    div.classList.add("alert");
    div.classList.add("alert-dark");
    div.style.color = "white";
    div.style.backgroundColor = "#878787";
    div.append(notification_txt);
    document.body.prepend(div);
  }
}